import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import FormItem from "../atoms/FormItem";
import { RootState } from "../../configureStore";

interface BorrowerInformationParams {
    index?: number;
}

const BorrowerInformation = ({
    index,
}: BorrowerInformationParams) => {
    const emailRequired = useSelector<RootState, boolean>(({ encompass: { origin } }) => Boolean(origin?.vendorIDCollection));
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const hasRequest = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => (activeBorrower ? (borrowers[activeBorrower].requests.length > 0 && borrowers[activeBorrower].taxPayerType === 'Individual') : false));

    return (
        <div className={(isAdd && hasRequest) ? 'none' : 'form-section mt-1'}>
            <h5>Primary Information</h5>
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem
                        label="First Name"
                        required
                        index={index}
                        rules={[{
                            pattern: /^[A-Za-z\- ]+$/,
                            message: 'Please only include characters or spaces',
                        }]}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Last Name"
                        required
                        index={index}
                        rules={[{
                            pattern: /^[A-Za-z\- ]+$/,
                            message: 'Please only include characters or spaces',
                        }]}
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem
                        type={isAdd ? "Input" : "Mask"}
                        label="SSN/TIN"
                        name="tin"
                        required
                        mask="999-99-9999"
                        rules={[
                            {
                                pattern: /\d{3}-\d{2}-\d{4}/,
                                message: 'Must be a valid and properly formatted SSN',
                            },
                        ]}
                        index={index}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Email Address"
                        name="email"
                        required={emailRequired}
                        extra="*Enter if you would like us to communicate directly with the borrower*"
                        index={index}
                        rules={[{
                            // eslint-disable-next-line no-control-regex
                            pattern: /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                            message: 'Must be a valid email address.',
                        }]}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BorrowerInformation;
