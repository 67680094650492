import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import FormItem from "../atoms/FormItem";
import { RootState } from "../../configureStore";

interface BusinessInformationParams {
    index?: number;
    existing?: boolean;
    onNameChange?: (name: string) => void;
}

const BusinessInformation = ({
    index,
    existing = false,
    onNameChange,
}: BusinessInformationParams) => {
    const emailRequired = useSelector<RootState, boolean>(({ encompass: { origin } }) => Boolean(origin?.vendorIDCollection));
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);

    return (
        <div className={isAdd ? 'none' : 'form-section'}>
            <h5>Business Entity Taxpayer Information</h5>
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem
                        label="Business Name"
                        required
                        index={index}
                        onChange={(e: any) => { onNameChange && onNameChange(e.target.value); }}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        index={index}
                        type="Mask"
                        label="EIN"
                        name="ein"
                        required
                        rules={[
                            {
                                pattern: /\d{2}-\d{7}/,
                                message: 'Must be a valid and properly formatted SSN',
                            },
                        ]}
                        mask="99-9999999"
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem
                        required={emailRequired && !existing}
                        index={index}
                        label="Email"
                        name="businessEmail"
                        rules={[{
                            // eslint-disable-next-line no-control-regex
                            pattern: /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                            message: 'Must be a valid email address.',
                        }]}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BusinessInformation;
